import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "@teamfabric/copilot-ui";
import App from "./App";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-react";
export const config = {
    url: process.env.UNLEASH_PROXY_URL ?? '',
    clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
    refreshInterval: 3600,
    appName: 'copilot-mfa-multisite',
    disableRefresh: true,
    context: {
        userId: window.sessionStorage.getItem("accountId") ?? '',
    },
};

export const client = new UnleashClient(config);

export default function Root() {
    return (
        <FlagProvider unleashClient={client}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <App />
            </ThemeProvider>
        </FlagProvider>
    );
}
